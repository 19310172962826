* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --basetheme: #f9a31c !important;
  --basethemelight: #f9a31c !important;
  --bordercolor: rgb(219, 224, 235);
  --fontcolor: black;
  --bodycolor: white;
  --tablecolor: white;
  --textcolor: white;
  --activecolor: #f9a31c !important;
  --bodycolor: white;
}




.form-check-input:checked {
  background-color: var(--activecolor) !important;
  border-color: var(--activecolor) !important;
}

.form-check-input:focus {
  box-shadow: none !important;
  border-color: var(--activecolor) !important;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: var(--activecolor) !important;
}

.btn-primary {
  background-color: var(--activecolor) !important;
  border-color: var(--activecolor) !important;

}

.form-label {
  font-weight: 600 !important;
}

.products {
  border: 1px solid var(--bordercolor);
  padding: 5px;
  border-radius: 8px;
  background-color: rgb(255 255 255);
  /* background-color:  rgb(247, 249, 252); */
}

.products-row {

  padding: 20px 8px;
  border-radius: 8px;

}

.search {
  display: flex !important;
  justify-content: center;
  align-items: center;

}

.search2 {
  width: 100%;
  max-width: 1000px !important;
}

.cont {
  min-height: 50vh !important;
}

.head {
  position: sticky !important;
  top: 0;
  background-color: white !important;
}
@media screen and (max-width: 400px) {
  .checks > div:nth-child(n+3) {
    display: block;
    margin-top: 5px; /* Adjust margin as needed */
  }
}

@media screen and (max-width:500px) {
  .img-box img {
    width: 250px !important;
    height: 50px;
  }
}



@media screen and (max-width:750px) {
  .search {
    justify-content: start !important;
  }
}

@media screen and (max-width:564px) {
  .search-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width:425px) {
  .content {
    flex-wrap: wrap;
  }
}

:where(.css-dev-only-do-not-override-1b0bdye).ant-pagination .ant-pagination-item-active,
:where(.css-1b0bdye).ant-pagination .ant-pagination-item-active {
  border-color: var(--activecolor) !important;
  background-color: var(--activecolor) !important;
  color: white !important;
}

.hover-color:hover {
  color: var(--activecolor);
}

.requested{
  font-size: 14px;
  color: rgb(128, 126, 126);
}